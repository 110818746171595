<template>
    <div>
        <div class="course-search-title">
            <el-row style="padding: 2vh 4vw;">
                <el-col>
                    <el-row>
                        <el-col v-if="getUserLoggedIn">
                            <div class="course-search-shortlist">
                                Shortlist Course
                                <i class="course-search-shortlist-icon el-icon-star-on" style="color: gold !important;"
                                   @click="shortlistCourse(getCourseData, 'remove')"
                                   v-if="courseShortlisted(getCourseData.code, getCourseData.level)"></i>
                                <i class="el-icon-star-off" @click="shortlistCourse(getCourseData, 'add')"
                                   v-else></i>
                            </div>
                        </el-col>
                        <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                            <el-row>
                                <el-col>
                                    <div class="courseTitle">{{getCourseData.title}}</div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col>
                                    <div class="courseDegree">{{getCourseData.qualification}}</div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :xs="12" :sm="12" :md="24" :lg="24" :xl="24" style=" margin: 1vh 0;">
                                    <div class="cao">
                                        Course CAO Code
                                        <span class="cao-value">
                                            <strong>{{getCourseData.code}}</strong>
                                        </span>
                                    </div>
                                </el-col>
                                <el-col :xs="12" :sm="12" :md="24" :lg="24" :xl="24" style="margin: 1vh 0;"
                                        v-if="getCourseData.cao_points !== 0">
                                    <div class="cao">
                                        CAO Points
                                        <span class="cao-value" v-if="getCourseData.cao_points !== 0">
                                            <strong>{{getCourseData.cao_points}}</strong>
                                        </span>
                                        <span class="cao-value" v-else>
                                            -
                                        </span>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                            <div style="display: flex; align-items: center; justify-content: center; height: 20vh;">
                                {{collegeName}}
                                <CollegeImage :key="getCourseData.level + '-' + getCourseData.college_name" :collegeName="getCourseData.college_name" :publicPath="publicPath" 
                                :imgStyle="{ 'max-height': '100%', 'max-width': '100%' }"></CollegeImage>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin: 1vh 0;" v-if="isMobile()">
                        <el-col :span="11">
                            <div class="summary-points" style="width: 100%;">
                                CAO Points Met
                                <strong>
                                    <span style="margin-left: 1vw;" v-if="getCourseData.cao_points !== 0">
                                        {{getCourseData.points_met}}
                                    </span>
                                    <span v-else>NA</span>
                                </strong>
                            </div>
                        </el-col>
                        <el-col :span="11" :offset="1">
                            <div class="summary-points" style="width: 100%;">Requirements Met
                                <strong>
                                    <span style="margin-left: 1vw;">{{getCourseData.requirements_met}}</span>
                                </strong>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <div class="quick-summary">
            <div style="display: flex; flex-wrap: wrap; flex-direction: row; align-items: center;"
                 :style="isMobile() ? {'justify-content': 'center'}:{}">
                <div class="summary-points" v-if="!isMobile()">
                    <div>CAO Points Met</div>
                    <div>
                        <strong>
                            <span v-if="getCourseData.cao_points !== 0">
                                {{getCourseData.points_met}}
                            </span>
                            <span v-else>NA</span>
                        </strong>
                    </div>
                </div>
                <el-col :xl="3" :lg="3" :md="3" :xs="4" :sm="4" class="summary-points" v-if="!isMobile()">
                    <div>Requirements Met
                    </div>
                    <div>
                        <strong>{{getCourseData.requirements_met}}</strong>
                    </div>
                </el-col>
                <div class="summary-points" v-if="getCourseData.level !== ''">
                    <div>Level</div>
                    <div :title="getCourseData.level"
                         style="width: 100%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 0 0.6vw;">
                        <strong>{{getCourseData.level}}</strong>
                    </div>
                </div>
                <div class="summary-points" v-if="getCourseData.fees !== ''">
                    <div>Fees</div>
                    <div><strong>€{{getCourseData.fees}}</strong></div>
                </div>
                <div class="summary-points" v-if="getCourseData.total_students !== 0">
                    <div>College Size</div>
                    <div>
                        <strong>{{getCourseData.total_students}}</strong>
                    </div>
                </div>
                <div v-if="getCourseStatus === 2 && getIsPremiumUser && getCourseData.yooni_fit.overall !== 'NA'"
                     class="yooni-fit">
                    <div>Skillsvista Fit</div>
                    <div>
                        <strong>{{getCourseData.yooni_fit.overall}}%</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
  import CollegeImage from '../../CommonComponents/CollegeImage.vue';
  
  export default {
    name: 'CourseCollegeDesktopTop',
    props: {
      courseDetails: {},
    },
    components: { CollegeImage },
    mounted: function() {
    },
    data() {
      return {
        starButtonClicked: false,
        // courseDetails: {
        //   CAO: 650,
        //   course_code: "CCT1234",
        //   college_name: "University College Cork",
        // },
        courseData: {title: ''},
        page: {
          shortlist: 'Shortlist Course',
          course_search: 'Course Search',
          course_title: 'Course Title',
          course_cao: 'Course CAO Code',
          cao_points: 'CAO Points',
          points_met: 'Points Met',
          requirementsMet: 'Requirements Met',
        },
        pointsMet: true,
        requirementsMet: true,
      };
    },
    methods: {},
  };
</script>


<style scoped>

    .quick-summary {
        background: transparent;
        padding: 10px 5px;
    }

</style>
