<template>
    <el-dialog
            class="register-guidance"
            :append-to-body="true"
            :visible.sync="dialogFormVisible"
            :width="isMobile() ? '90%':'50%'"
            @close="$emit('close')">
        <loading-screen v-if="$store.state.loadingScreen"></loading-screen>
        <div class="paymentHeadPopover" slot="title"
             :style="isMobile() ? {width: '100%'} : {width: '80%'}"
             style="text-align: justify;margin: 2vh 0; padding: 0 16px;">
            Register as a Guidance Counsellor and access the results of your students through the Skillsvista Fit
            Portal.
        </div>
        <el-form :model="form" style="padding: 0 16px;">
            <el-form-item label="Full Name">
                <el-input autocomplete="off" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="Email Address">
                <el-input autocomplete="off" type="'email'" v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="School">
                <!--                <el-input autocomplete="off" type="'email'" v-model="form.school"></el-input>-->
                <el-select
                        style="display: block;"
                        filterable placeholder="Select School" allow-create
                        v-model="form.school">
                    <el-option
                            :key="index"
                            :label="item.value"
                            :value="item.value"
                            v-for="(item, index) in $store.state.schools">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="School Role Number">
                <el-input autocomplete="off" type="'email'" v-model="form.school_roll_number"></el-input>
            </el-form-item>
            <el-form-item label="Phone Number">
                <el-input autocomplete="off" type="'email'" v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="What’s your intended use for Skillsvista? (School or Private Guidance Counselling Service)">
                <el-input autocomplete="off" type="'email'" v-model="form.intended_use"></el-input>
            </el-form-item>

        </el-form>
        <div class="dialog-footer" slot="footer">
            <el-button @click="dialogFormVisible = false">Cancel</el-button>
            <el-button type="primary" @click="registerCounsellor" :disabled="getAllFieldFilled">Confirm</el-button>
        </div>
    </el-dialog>
</template>

<script>
  import LoadingScreen from './LoadingScreen';

  export default {
    name: 'RegisterGuidanceCounsellor',
    components: {LoadingScreen},
    props: ['showDialog'],
    mounted() {
      this.dialogFormVisible = this.showDialog;
      let self = this;
      self.$store.state.loadingScreen = true;
      self.getSchools().then(response3 => {
        self.$store.state.loadingScreen = false;
      });
    },
    data: function() {
      return {
        dialogFormVisible: false,
        form: {
          email: '',
          name: '',
          school: '',
          school_roll_number: '',
          phone: '',
          intended_use: '',
        },
      };
    },
    computed: {
      getAllFieldFilled: function() {
        return this.form.email === '' ||
            this.form.name === '' ||
            this.form.school === '' ||
            this.form.school_roll_number === '' ||
            this.form.phone === '' ||
            this.form.intended_use === '';
      },
    },
    methods: {
      registerCounsellor: function() {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/register-gc/';
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(self.form),
          success: function(response) {
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Registration successful.',
              message: 'Please check your email!',
              type: 'success',
              duration: 5000,
              showClose: true,
            });
            self.$emit('close');
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Registration not successful.',
              message: 'Some error occurred. Please try again later.',
              type: 'error',
              duration: 3000,
              showClose: true,
            });
          },
        });
      },
    },
  };
</script>

<style>
    .register-guidance .el-dialog__body {
        padding-top: 0;
        padding-bottom: 0;
    }

    .register-guidance .el-dialog__body .el-form-item__label {
        font-size: 16px;
    }
</style>