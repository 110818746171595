<template>
    <div v-if="!$store.state.loadingScreen && Object.keys(collegeData).length !== 0"
         :style="isMobile()?{height: '75vh'}:{height: '99.5vh'}"
         style="overflow-y: auto; overflow-x: hidden;">
        <div class="show-course" v-if="collegeData.no_of_courses > 0">
            <div id="show-course" @click="$router.push('/filter?college=' + collegeData.name);"
                 class="show-course-sticky">
                Show courses
            </div>
            <div class="arrow-right"></div>
        </div>
        <el-row style="display: flex; align-items: center">
            <el-col :span="8" class="component-title" style="cursor: pointer;" v-if="!isMobile()">

            </el-col>
            <el-col :span="8" v-else>
                &nbsp;
            </el-col>
            <el-col :offset="14" :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row class="header-row"></el-row>
        <el-row style="background: white;">
            <el-row>
                <img :src="`${publicPath}img/colleges/${collegeData.name}/cover.png`" alt="no image"
                     style="width: 100%; max-height: 30vh"
                />
                <div class="college-name">{{collegeData.name}}</div>
                <img :src="`${publicPath}img/colleges/${collegeData.name}.svg`" alt="no image"
                     class="college-logo" v-if="collegeData.name !== 'City Colleges'"/>
                <div :style="isMobile() ?{'margin-top': '16px'}:{top: '1vh', right: '5vw', position: 'absolute'}"
                     style="">
                    <img v-if="collegeData.linkedin !== ''" class="social-media-img"
                         @click="openSocialMedia(collegeData.linkedin)"
                         :src="`${publicPath}img/icons8-linkedin.svg`" alt="linkedin"/>
                    <img v-if="collegeData.twitter !== ''" class="social-media-img"
                         @click="openSocialMedia(collegeData.twitter)"
                         :src="`${publicPath}img/icons8-twitter.svg`" alt="twitter"/>
                    <img v-if="collegeData.facebook !== ''" class="social-media-img"
                         @click="openSocialMedia(collegeData.facebook)"
                         :src="`${publicPath}img/icons8-facebook.svg`" alt="facebook"/>
                </div>
            </el-row>
            <el-row v-if="!isMobile()" class="sub-header">
                <div style="width: 50%;" class="sub-header-1">
                    <div v-if="collegeData.website !== ''" class="college-info">
                        <img :src="`${publicPath}img/website-logo.svg`" style="margin-right: 5px;" alt="-">
                        <a @click="openSocialMedia(collegeData.website)">{{collegeData.website}}</a>
                    </div>
                    <div v-if="collegeData.phone !== ''" class="college-info">
                        <img :src="`${publicPath}img/contact.svg`" style="margin-right: 5px;" alt="-">
                        {{collegeData.phone}}
                    </div>
                    <div v-if="collegeData.email !== ''" class="college-info">
                        <img :src="`${publicPath}img/email.svg`" style="margin-right: 5px;" alt="-">
                        <a :href="'mailto:' + collegeData.email + '?subject=Query Email from Skillsvista'"
                           target="_blank">
                            Send email</a>
                    </div>
                </div>
                <div style="width: 50%;" class="sub-header-2">
                    <div v-if="collegeData.undergrad_courses !== ''" class="extra-tabs"
                         @click="openSocialMedia(collegeData.undergrad_courses)">
                        Undergrad Courses
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div v-if="collegeData.scholarships !== ''" class="extra-tabs"
                         @click="openSocialMedia(collegeData.scholarships)">
                        Scholarships
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div v-if="collegeData.prospectus !== ''" class="extra-tabs"
                         @click="openSocialMedia(collegeData.prospectus)">
                        Download Prospectus
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </el-row>
            <el-row v-else class="sub-header">
                <div v-if="collegeData.website !== ''" class="college-info">
                    <img :src="`${publicPath}img/website-logo.svg`" style="margin-right: 5px;" alt="-">
                    <a @click="openSocialMedia(collegeData.website)">{{collegeData.website}}</a>
                </div>
                <div v-if="collegeData.undergrad_courses !== ''" class="extra-tabs"
                     @click="openSocialMedia(collegeData.undergrad_courses)">
                    Undergrad Courses
                    <i class="el-icon-arrow-right"></i>
                </div>
                <div v-if="collegeData.phone !== ''" class="college-info">
                    <img :src="`${publicPath}img/contact.svg`" style="margin-right: 5px;" alt="-">
                    {{collegeData.phone}}
                </div>
                <div v-if="collegeData.scholarships !== ''" class="extra-tabs"
                     @click="openSocialMedia(collegeData.scholarships)">
                    Scholarships
                    <i class="el-icon-arrow-right"></i>
                </div>
                <div v-if="collegeData.email !== ''" class="college-info">
                    <img :src="`${publicPath}img/email.svg`" style="margin-right: 5px;" alt="-">
                    <a :href="'mailto:' + collegeData.email + '?subject=Query Email from Skillsvista'" target="_blank">
                        Send email</a>
                </div>
                <div v-if="collegeData.prospectus !== ''" class="extra-tabs"
                     @click="openSocialMedia(collegeData.prospectus)">
                    Prospectus
                    <i class="el-icon-arrow-right"></i>
                </div>
            </el-row>
        </el-row>
        <el-row v-if="isMobile()" style="padding: 16px; background: white;">
            <div class="section-heading section-heading-main">
                Featured Courses
            </div>
            <el-row style="margin: 0.5vh 0;" v-for="(course, index) in collegeData.new_courses" :key="index">
                <el-col :span="22">
                    <div class="related-course">
                        <img @click="openSocialMedia(course.link)" :src="`${publicPath}img/bullet-point-1.svg`"
                             style="margin-right: 10px;" alt="-">
                        <a @click="openSocialMedia(course.link)">{{getNewCoursesFormatted(course)}}</a>
                    </div>
                </el-col>
            </el-row>
        </el-row>
        <el-row :style="isMobile() ? {padding: '0'} : {padding: '16px'}">
            <el-col :xl="14" :lg="14" :md="{span: 24}" :sm="24" :xs="24"
                    :style="!isMobile()? {padding: '0'}:{}">
                <el-row :gutter="!isMobile() ? 20:0" style="margin: 16px;"
                        v-for="(section, index) in collegeData.sections"
                        :key="index" :style="section.styleObj">
                    <el-col class="section-heading section-heading-main">
                        {{section.title}}
                    </el-col>
                    <el-col v-for="(column, key) in section.columns" :key="key"
                            :xs="column.mobile_size" :sm="column.mobile_size"
                            :md="column.web_size" :lg="column.web_size" :xl="column.web_size"
                            v-if="(column.visible === 'web' && !isMobile()) || (column.visible === 'mobile' && isMobile()) || column.visible === ''"

                            :style="column.styleObj" style="margin-top: 1vh; margin-bottom: 1vh;">
                        <div class="section-text" v-if="column.type === 'text'">
                            {{column.data}}
                        </div>
                        <div v-if="column.type === 'video'">
                            <iframe style="border: none; max-height: 300px; min-height: 200px;" allowfullscreen
                                    :src="column.data"
                                    width="100%"></iframe>
                        </div>
                        <div v-if="column.type === 'image'">
                            <img :src="`${publicPath}/${column.data}`"
                                 style="border: none; max-height: 300px; min-height: 200px; width: 100%" alt="-">
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :xl="{span: 8, offset: 2}" :lg="{span: 8, offset: 2}" :md="24" :sm="24"
                    :xs="24" :style="!isMobile()? {padding: '0 16px'}:{}">
                <el-row v-if="!isMobile()" style="background: white; margin: 2vh 0; padding: 16px;">
                    <div class="section-heading section-heading-main">
                        New Courses
                    </div>
                    <el-row style="margin: 0.5vh 0;" v-for="(course, index) in collegeData.new_courses"
                            :key="index">
                        <el-col :span="22">
                            <div class="related-course">
                                <img :src="`${publicPath}img/bullet-point-1.svg`" style="margin-right: 10px;" alt="-">
                                <a @click="openSocialMedia(course.link)">{{getNewCoursesFormatted(course)}}
                                </a>
                            </div>
                        </el-col>
                    </el-row>
                </el-row>
                <el-row :style="isMobile() ? {margin: '16px'} : {}">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                            :style="!isMobile() ? {'padding-right': '8px'}:{}">
                        <div class="college-pillar-fit">
                            <div class="section-heading section-heading-main">
                                Our Schools
                            </div>
                            <div class="related-course" v-for="(school, index) in collegeData.schools"
                                 :key="index" @click="openSocialMedia(school.link)">
                                <a target="_blank">
                                    {{school.name}}
                                </a>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
                            :style="{'margin-top': '16px'}">
                        <div class="college-pillar-fit">
                            <div class="section-heading section-heading-main">
                                Amazing facts
                            </div>
                            <div class="related-course" v-for="(fact, index) in collegeData.facts" :key="index">
                                <div class="dot" :style="{backgroundColor: colorPalette[index]}"></div>
                                <span class="personality">{{fact}}</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin: 2vh 0; padding: 16px;" v-for="(section, index) in collegeData.sidebar_sections"
                        :key="index" :style="section.styleObj">
                    <el-col>
                        <div class="section-heading section-heading-main">
                            {{section.title}}
                        </div>
                    </el-col>
                    <el-col v-for="(column, key) in section.columns" :key="key" :xl="column.web_size"
                            :lg="column.web_size"
                            :md="column.web_size" :sm="column.mobile_size" :xs="column.mobile_size"
                            :style="column.styleObj"
                            style="margin-top: 1vh; margin-bottom: 1vh;">
                        <div class="section-text" v-if="column.type === 'text'">
                            {{column.data}}
                        </div>
                        <div v-if="column.type === 'video'">
                            <iframe style="border: none; max-height: 300px; min-height: 200px;" allowfullscreen
                                    :src="column.data"
                                    width="100%"></iframe>
                        </div>
                        <div v-if="column.type === 'image' && column.text === ''">
                            <img :src="`${publicPath}/${column.data}`"
                                 style="border: none; max-height: 300px; min-height: 200px; width: 100%;" alt="-">
                        </div>
                        <div v-if="column.type === 'image' && column.text !== ''">
                            <el-avatar shape="circle" style="width: calc(7vh + 2vw); height: calc(7vh + 2vw);"
                                       :fit="'fill'" :src="`${publicPath}/${column.data}`"></el-avatar>
                            <div style="text-align: center" class="salary-text">{{column.text}}</div>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin: 16px; padding: 16px; background: white;">
                    <el-col :xl="18" :lg="18" :md="18" :sm="16" :xs="16">
                        <div class="section-heading section-heading-main" style="text-align: left;">
                            Got a question for {{collegeData.name}}?
                        </div>
                    </el-col>
                    <el-col :xl="6" :lg="6" :md="6" :sm="8" :xs="8">
                        <img :src="`${publicPath}img/colleges/${collegeData.name}/logo.png`" alt="no image"
                             style="max-width: 100%; max-height: 8vh;"/>
                    </el-col>
                    <el-col>
                        <el-form v-model="collegeQueryForm">
                            <el-form-item label="Name">
                                <el-input type="text" v-model="collegeQueryForm.name"></el-input>
                            </el-form-item>
                            <el-form-item label="Email">
                                <el-input type="text" v-model="collegeQueryForm.email"></el-input>
                            </el-form-item>
                            <el-form-item label="Question">
                                <el-input type="textarea"
                                          :rows="3" v-model="collegeQueryForm.question"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col>
                        <el-button type="primary" @click="submitQuery">Submit</el-button>
                    </el-col>
                </el-row>

            </el-col>
        </el-row>
    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'CollegeInfo',
    components: {ProfileAvatar},
    props: ['collegeName'],
    mounted() {
      this.sendAnalyticsEvents('college_page', this.collegeData.name);
      this.getCollegeData();
    },
    data: function() {
      return {
        colorPalette: ['#CC0044', '#428B98', '#5C4C87', '#3D95CA', '#FDC300', '#B66289'],
        collegeQueryForm: {
          name: '',
          email: '',
          question: '',
          college_name: '',
        },
        collegeData: {},
      };
    },
    methods: {
      getCollegeData: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        let url = `${self.getBaseUrl()}/keywords/college/info/${self.collegeName}/`;
        $.ajax({
          url: url,
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          type: 'GET',
          success: function(response) {
            if (response.length > 0)
              self.collegeData = response[0];
            self.$store.state.loadingScreen = false;
            // self.$message({
            //   title: 'Successful',
            //   message: 'You have successfully registered your query.',
            //   type: 'success',
            //   duration: 3000,
            //   showClose: true,
            // });
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
          },
        });
      },
      submitQuery: function() {
        let self = this;
        let url = self.getBaseUrl() + '/keywords/register-question/';
        self.collegeQueryForm.college_name = self.collegeData.name;
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(self.collegeQueryForm),
          success: function(response) {
            self.$message({
              title: 'Successful',
              message: 'You have successfully registered your query.',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
            self.collegeQueryForm = {
              name: '',
              email: '',
              question: '',
            };
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            self.$message({
              title: 'Unsuccessful',
              message: 'Some error occurred. Please try again later.',
              type: 'error',
              duration: 3000,
              showClose: true,
            });
            self.$store.state.loadingScreen = false;
          },
        });
      },
      openSocialMedia: function(link) {
        if (!link.includes('http:')) {
          link = 'https://' + link;
        }
        let win = window.open(link, '_blank');
        win.focus();
      },
      getNewCoursesFormatted: function(course) {
        if (course !== undefined) {
         if (course.code)
            return course.code + ' - ' + course.title;
          return course.title;
        }
        return '';
      },
    },
  };
</script>

<style scoped>
    .show-course {
        width: auto;
        position: absolute;
        right: 0;
        top: calc(50% - 25px);
        z-index: 9999;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .show-course-sticky {
        background: rgba(238, 120, 39, 0.8);
        padding: 0 10px;
        color: white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow-right {
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 40px solid rgba(238, 120, 39, 0.8);
    }

    a {
        cursor: pointer;
        color: rgba(19, 19, 54, 0.75) !important
    }

    .awards {
        text-align: justify;
        color: #333333;
    }

    .personality {
        margin-left: 10px;
    }

    .salary-text {
        font-weight: normal;
        color: #333333;
        text-align: left;
        margin: 1vh 0;
    }

    .dot {
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }


    .college-pillar-fit {
        background: #DAE5ED;
        border-radius: 3px;
    }

    .related-course {
        font-weight: 500;
        display: flex;
        align-items: center;
        color: #5C5C5C;
        text-align: left;
    }

    .header-row {
        background: #3A3F5C;
        height: 5vh;
        width: 100%;
    }

    .college-name {
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: bolder;
        text-align: center;
        color: #ffffff;
        padding: 16px;
        background: rgba(58, 49, 96, 0.5);
    }

    .extra-tabs {
        /*color: rgba(19, 19, 54, 0.9) !important;*/
        height: 4vh;
        background: #59A3CC;
        border-radius: 60px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        align-items: center;
    }

    .extra-tabs div {
        text-align: center;
        width: 100%;
        margin-right: 0;
    }

    .extra-tabs:hover {
        cursor: pointer;
        text-decoration: underline;
    }


    .section-heading {
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: normal;
        text-align: justify;
        color: #3A3160;
        margin-bottom: 1vh;
    }

    .section-text {
        font-weight: 300;
        /* color: #333333; */
        color: white;
        text-align: justify;
    }

    .social-media-img {
        /*margin-right: 10px;*/
        cursor: pointer;
    }

    .barDiv {
        height: 30px;
        width: 80%;
        margin: 0 auto;
    }

    .barDivText1, .barDivText2, .barDivText3 {
        opacity: 0.7;
        font-weight: 700;
        font-size: 12px;
    }

    .barDiv1 {
        float: left;
        height: 100%;
        width: 20%;
        background: #ECAA77;
    }

    .barDiv2 {
        float: left;
        height: 100%;
        width: 50%;
        background: #4183A3;
    }

    .barDivText1 {
        float: left;
        width: 20%;
        text-align: center;
    }

    .barDivText2 {
        float: left;
        text-align: center;
        width: 50%;
    }

    .barDiv2:after {
        content: '';
        border-right: 2px white dashed;
        height: 30px;
        transform: translateX(-50%);
        display: block;
    }

    .barDiv3 {
        float: left;
        height: 100%;
        width: 30%;
        background: #4BA7A3;
    }

    .college-schools {
        font-weight: 300;
        text-align: justify;
        color: #333333;
    }

    .barDivText3 {
        float: left;
        width: 30%;
        text-align: center;
    }

</style>
