<template>
    <div :style="isMobile()?{height: '75vh'}:{height: '95vh'}" style="overflow-y: auto;">
        <el-row style="display: flex; align-items: center;">
            <el-col :span="22"
                    :style="!isMobile() ? {fontSize: '1.3vw', lineHeight: '1.6vw'}:{fontSize: 'calc(1.2vw + 1.2vh)', lineHeight: 'calc(2.4vw + 2.4vh)'}"
                    class="component-title">
                Counsellor: <strong>{{$store.state.studentFullName}}</strong><br>
                School: {{$store.state.schoolName}}
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row>
            <data-tables :filters="filters" :data="getStudentDetails" :page-size="10"
                         style="width: 100%;"
                         :pagination-props="{ pageSizes: [10] }">
                <el-row slot="tool" style="margin: 10px 0">
                    <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                        <el-input placeholder="Search Student" v-model="filters[0].value"></el-input>
                    </el-col>
                </el-row>
                <el-table-column label="Student Name" :prop="'name'" width="auto"></el-table-column>
                <el-table-column label="Student Email" :prop="'email'" width="auto"></el-table-column>
                <!--                <el-table-column label="Email" :prop="'email'" width="auto"></el-table-column>-->
                <el-table-column label="Predicted Points" :prop="'points'" width="auto">
                    <template slot-scope="scope">
                        <i v-if="cellPointsValueRenderer(scope.row) === 'Yes'" class="el-icon-check"
                           style="font-size: 25px;"></i>
                        <i v-else class="el-icon-close" style="font-size: 25px;"></i>
                    </template>
                </el-table-column>
                <el-table-column label="Skillsvista fit Assessment" :formatter="cellValueRenderer"
                                 :prop="'recommendations_generated'" width="auto">
                    <template slot-scope="scope">
                        <i v-if="cellValueRenderer(scope.row) === 'Yes'" class="el-icon-check"
                           style="font-size: 25px;"></i>
                        <i v-else class="el-icon-close" style="font-size: 25px;"></i>
                    </template>
                </el-table-column>
                <el-table-column label="Permission to Access" :formatter="renderPermissionToAccess"
                                 :prop="'school_counsellor_access'" width="auto">
                    <template slot-scope="scope">
                        <i v-if="renderPermissionToAccess(scope.row) === 'Yes'" style="font-size: 25px;"
                           class="el-icon-check"></i>
                        <i v-else class="el-icon-close" style="font-size: 25px;"></i>
                    </template>
                </el-table-column>
                <el-table-column
                        label="Access Account Here"
                        width="auto">
                    <template slot-scope="scope">
                        <el-button type="text" size="medium"
                                   v-if="cellPointsValueRenderer(scope.row) === 'Yes' && cellValueRenderer(scope.row) === 'Yes' && renderPermissionToAccess(scope.row) === 'Yes' && (scope.row.school_counsellor_access || scope.row.private_guidance_counsellor)"
                                   @click.native.prevent="showReport(scope.$index, scope.row)">
                            <img :src="`${publicPath}img/external-link.svg`" style="width: 25px;"/>
                        </el-button>
                        <i v-else class="el-icon-close" style="font-size: 25px;"></i>
                    </template>
                </el-table-column>
                <el-table-column label="Last Login" :prop="'login'" width="auto"></el-table-column>
            </data-tables>
        </el-row>
    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'CounsellorHome',
    components: {ProfileAvatar},
    mounted() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.checkUserLoggedIn().then(response => {
        if (self.$store.state.isAdmin) {
          self.$router.push('/admin/');
        }
        if (response['user_type'] === 'counsellor') {
          self.$store.state.showSidebar = false;
        }
        self.$store.state.loadingScreen = true;
        self.getCounsellorStudents().then(response => {
          self.$store.state.loadingScreen = false;
        }).catch(error => {
          self.$store.state.loadingScreen = false;
        });
        self.$store.state.counsellorStudentName = '';
        self.$store.state.counsellorStudentId = '';
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    computed: {
      getStudentDetails: function() {
        return this.studentDetails;
      },
    },
    methods: {
      cellPointsValueRenderer: function(row) {
        if (row.points !== null && row.points !== '')
          return 'Yes';
        return 'No';
      },
      cellValueRenderer: function(row) {
        if (row.recommendations_generated)
          return 'Yes';
        return 'No';
      },
      renderPermissionToAccess: function(row) {
        if (row.school_counsellor_access || row.private_guidance_counsellor)
          return 'Yes';
        return 'No';
      },
      showReport: function(index, row) {
        this.$router.push('/counsellor/report/' + row.id + '/' + row.name + '/');
      },
      getCounsellorStudents: function() {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/counsellor/students/list';
        return new Promise((resolve, reject) => {
          $.ajax({
            beforeSend: function(request) {
              request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
            },
            url: url,
            type: 'GET',
            success: function(response) {
              self.studentDetails = response;
              resolve(true);
            },
            error: function(error) {
              console.log(error);
              reject(false);
            },
          });
        });

      },
    },
    data: function() {
      return {
        loadingScreen: false,
        studentDetails: [],
        filters: [
          {
            value: '',
            prop: ['name', 'email'],
          }],
      };
    },
  };
</script>

<style scoped>

</style>
